<script>
    import Layout from "../../../layouts/main";
    import PageHeader from "@/components/page-header";
    import appConfig from "@/app.config";
    import Aws from "@/services/Aws";
    import Monitor from "@/services/Monitor";

    /**
     * Application Details component
     */
    export default {
        components: {
            Layout,
            PageHeader
        },
        page: {
            title: "AWS Application",
            meta: [
                {
                    name: "description",
                    content: appConfig.description,
                },
            ],
        },
        data() {
            return {
                title: "",
                items: [
                    {
                        text: "AWS Applications",
                    },
                    {
                        text: "Application Details",
                        active: true,
                    },
                ],
                applicationData: [],
                DBClusterIdentifier: '',
                DBinstances: [],
                TargetGroupArn: '',
                LoadBalancerArn: '',
                loadBalancerData: [],
                TargetGroupData:[],
                rds_cluster: '',
                error: null,
                modalData: {},
                toggleProfile: false,
                showLoader: false,
                loadBalancerDataSpinner: false,
                DBinstancesSpinner: false,
                responseTimeWidget:'',
                requestCountWidget:'',
                chart1Loader: false, // Loader for Chart 1
                chart2Loader: false, // Loader for Chart 2
                dataRecurringPeriod: "", // Selected refresh period in seconds
                refreshInterval: null, // Holds the interval ID
                progress: 0, // Progress percentage for the progress bar
                timeRemaining: 0, // Remaining time until the next action
                matchsesData:[],
                TargetGroupName: ''
            };
        },
        created() {
            const options = { year: 'numeric', month: 'long', day: 'numeric' }; // Customize the format
            this.today = new Date().toLocaleDateString(undefined, options); // Use browser's locale settings
        },
        async mounted() {
            this.getApplications()
        },
        methods: {
            pageTitle() {
                this.title = 'Application: ' + this.applicationData.name
            },

            monitoringMatches(){

                Monitor.getMatches().then((response) => {
                    if(response.data.data){
                        this.matchsesData = response.data.data;
                    }
                }).catch((error) => {
                    this.error = error.response.data.error
                        ? error.response.data.error
                        : "";
                }).finally(() => {

                });
            },

            targetGroupCharts(){
                this.chart1Loader = true
                this.chart2Loader = true
                Aws.targetGroupCharts(this.TargetGroupArn, this.LoadBalancerArn).then((response) => {
                    this.responseTimeWidget= response.data.data.responseTimeWidget;
                    this.requestCountWidget= response.data.data.requestCountWidget;
                }).catch((error) => {
                    this.error = error.response.data.error
                        ? error.response.data.error
                        : "";
                }).finally(() => {
                    this.chart1Loader = false
                    this.chart2Loader = false
                });
            },

            getApplications() {

                this.showLoader = true
                this.TargetGroupArn = ''
                this.monitoringMatches()

                Aws.getApplications({
                    application_id: this.$route.params.id
                }).then((response) => {
                    if(response.data.data){
                        this.applicationData = response.data.data[0];

                        this.pageTitle()

                        if(this.applicationData.resources){
                            this.applicationData.resources.forEach((value) => {
                                if(value.resource_type == 'targetgroups'){
                                    this.TargetGroupArn = value.resource_id
                                    this.getTargetGroupInstances()
                                }

                                if(value.resource_type == 'loadbalancers'){
                                    this.getLoadBalancers(value.resource_id)
                                    this.LoadBalancerArn = value.resource_id
                                }

                                if(value.resource_type == 'rds_cluster'){
                                    this.getRDSInstances(value.resource_id)
                                    this.DBClusterIdentifier = value.resource_id
                                }
                            })

                            if(this.TargetGroupArn && this.LoadBalancerArn){
                                this.targetGroupCharts()
                            }
                        }
                    }

                }).catch((error) => {
                this.error = error.response.data.error
                    ? error.response.data.error
                    : "";
                }).finally(() => {
                    this.showLoader = false;
                });
            },

            async getLoadBalancers(LoadBalancerArn) {

                try {
                    this.loadBalancerDataSpinner = true
                    const response = await Aws.getLoadBalancers({
                        LoadBalancerArn: LoadBalancerArn
                    });
                    if(response.data.data){
                        this.loadBalancerData = response.data.data[0]
                    }
                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";

                } finally {
                    this.loadBalancerDataSpinner = false;
                }
            },

            getRDSInstances(DBClusterIdentifier) {
                this.DBinstancesSpinner = true
                Aws.getRDSInstances({
                    DBClusterIdentifier: DBClusterIdentifier
                })
                    .then((response) => {
                        if (response.data) {
                            let DBinstances = response.data.data;
                            this.DBinstances = DBinstances
                        }
                    })
                    .catch((error) => {
                        this.error = error.response && error.response.data && error.response.data.error
                            ? error.response.data.error
                            : "An error occurred";
                    }).finally(() => {
                        this.DBinstancesSpinner = false
                    });
            },

            toggle() {
                this.toggleProfile = !this.toggleProfile;
            },

            isNumeric(value) {
                return !isNaN(parseFloat(value)) && isFinite(value);
            },

            dataRecurringRefresh() {
                if (this.refreshInterval) {
                    clearInterval(this.refreshInterval);
                    this.refreshInterval = null;
                }

                if (this.dataRecurringPeriod) {
                    const period = parseInt(this.dataRecurringPeriod, 10); // Refresh period in seconds
                    this.timeRemaining = period; // Reset the timer
                    this.progress = 0; // Reset progress

                    let isPaused = false; // Flag to pause execution

                    // Start a new interval for refreshing charts and updating the progress bar
                    this.refreshInterval = setInterval(() => {
                        if (isPaused) return; // Skip execution if paused

                        if (this.timeRemaining > 0) {
                            this.timeRemaining--;
                            this.progress = ((period - this.timeRemaining) / period) * 100;
                        } else {
                            // Call getApplications and pause for 2 seconds
                            this.getApplications();
                            isPaused = true;

                            setTimeout(() => {
                                this.timeRemaining = period; // Reset timer for the next interval
                                this.progress = 0; // Reset progress bar
                                isPaused = false; // Resume execution
                            }, 2000);
                        }
                    }, 1000);
                }
            },

            async getTargetGroupInstances() {
                try {
                    this.isBusy = true;
                    const response = await Aws.getTargetGroupInstances(this.TargetGroupArn);
                    this.TargetGroupData = response.data.data.instances;
                    this.TargetGroupName = response.data.data.targetGroupName;

                } catch (error) {
                    this.error = error.response.data.error ? error.response.data.error : "";
                }
            },

        },
        beforeDestroy() {
            if (this.refreshInterval) {
                clearInterval(this.refreshInterval);
            }
        },
    };
</script>

<template>
    <Layout>
        <PageHeader :title="title" :items="items" />

        <div class="row mb-4" v-if="!this.error">
            <div v-if="!toggleProfile" class="col-xl-4">
                <div class="card h-100">
                    <div class="card-body" >
                        <div class="text-center">

                            <template>
                                <div>
                                    <div class="input-group mb-1">
                                        <button type="button" class="btn btn-primary" @click="getApplications">
                                            Refresh Data
                                        </button>
                                        <select class="form-select" v-model="dataRecurringPeriod" @change="dataRecurringRefresh">
                                            <option value="" selected>Select Refresh Period</option>
                                            <option value="30">30 Seconds</option>
                                            <option value="60">1 Minute</option>
                                            <option value="300">5 Minutes</option>
                                        </select>
                                    </div>

                                    <!-- Progress Bar -->
                                    <div class="progress" v-if="dataRecurringPeriod">
                                        <div
                                                class="progress-bar progress-bar-striped progress-bar-animated"
                                                role="progressbar"
                                                :aria-valuenow="progress"
                                                aria-valuemin="0"
                                                aria-valuemax="100"
                                                :style="{ width: `${progress}%` }"
                                        >
                                            <!--{{ timeRemaining }}s-->
                                        </div>
                                    </div>
                                </div>
                            </template>

                            <div class="clearfix"></div>
                        </div>

                        <div class="mt-4 mb-0" v-if="!showLoader">

                            <hr class="my-2 mb-2" />

                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <h5>RDS Databases: {{DBClusterIdentifier}}</h5>
                                </div>

                                <table class="table table-responsive table-sm">
                                    <thead>
                                    <tr>
                                        <th>DB Identifier</th>
                                        <th>CPU Utilization</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="item in DBinstances" :key="item.DBInstanceIdentifier">
                                        <td>{{item.DBInstanceIdentifier}}</td>
                                        <td>
                                            <div class="progress position-relative" v-if="isNumeric(item.CPUUtilization)" style="height: 25px;">
                                                <div
                                                        class="progress-bar progress-bar-striped progress-bar-animated"
                                                        role="progressbar"
                                                        :aria-valuenow="item.CPUUtilization"
                                                        aria-valuemin="0"
                                                        aria-valuemax="100"
                                                        :style="{ width: `${item.CPUUtilization}%` }"
                                                ></div>
                                                <span class="position-absolute w-100 text-center cpuProgressValue">
                                                    {{ parseFloat(item.CPUUtilization).toFixed(2) }}%
                                                </span>
                                            </div>
                                            <div v-else class="text-danger">
                                                Invalid CPU Utilization value
                                            </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>

                            </div>

                            <div class="row">
                                <div class="col-sm-12 col-md-12">
                                    <h5>Target Group Instances: {{TargetGroupName}}</h5>
                                </div>
                                <table class="table table-responsive table-sm">
                                    <thead>
                                    <tr>
                                        <th>Instance Name</th>
                                        <th>CPU Utilization</th>
                                    </tr>
                                    </thead>
                                    <tbody>
                                    <tr v-for="item in TargetGroupData" :key="item.InstanceId">
                                        <td>
                                            <router-link title="View Instance Details" :to="{ path: `/aws/instances/${item.InstanceId}`}">
                                                <span v-if="item.InstanceName">{{item.InstanceName}}</span>
                                                <span v-else>{{item.InstanceId}}</span>
                                            </router-link>
                                        </td>
                                        <td>
                                              <div class="progress position-relative" v-if="isNumeric(item.CPUUtilization)" style="height: 25px;">
                                                  <div
                                                          class="progress-bar progress-bar-striped progress-bar-animated"
                                                          role="progressbar"
                                                          :aria-valuenow="item.CPUUtilization"
                                                          aria-valuemin="0"
                                                          aria-valuemax="100"
                                                          :style="{ width: `${item.CPUUtilization}%` }"
                                                  ></div>
                                                  <span class="position-absolute w-100 text-center cpuProgressValue">
                                                      {{ parseFloat(item.CPUUtilization).toFixed(2) }}%
                                                  </span>
                                              </div>
                                              <div v-else class="text-danger">
                                                  Invalid CPU Utilization value
                                              </div>
                                        </td>
                                    </tr>
                                    </tbody>
                                </table>
                            </div>

                        </div>
                        <div class="card-body d-flex align-items-center justify-content-center" v-else >
                            <b-spinner large></b-spinner>
                        </div>
                    </div>

                </div>
            </div>

            <!-- NAV TABS -->
            <div :class="!toggleProfile ? 'col-xl-8' : 'col-xl-10'">
                <!-- CHARTS CARD -->
                <div class="card mb-3">
                    <b-tabs content-class="p-4" justified class="nav-tabs-custom">

                        <b-tab active>
                           <!-- <template v-slot:title>
                                <i class="uil uil-list-ui-alt font-size-20"></i>
                                <span class="d-none d-sm-block">Charts/Statistics</span>
                            </template>-->
                            <template >
<!--                                <div class="row">
                                    <div class="col-6"></div>
                                    <div class="col-6 text-end">

                                    </div>
                                </div>-->
                                <div class="row">
                                    <!-- Chart 1 -->
                                    <div class="col-6 position-relative chart-container">
                                        <div :class="{ 'chart-overlay': chart1Loader }">
                                            <img v-if="responseTimeWidget" :src="responseTimeWidget" alt="Response Time Widget" class="img-fluid">
                                        </div>
                                        <div v-if="chart1Loader" class="spinner-overlay">
                                            <b-spinner large></b-spinner>
                                        </div>
                                    </div>

                                    <!-- Chart 2 -->
                                    <div class="col-6 position-relative chart-container">
                                        <div :class="{ 'chart-overlay': chart2Loader }">
                                            <img v-if="requestCountWidget" :src="requestCountWidget" alt="Request Count Widget" class="img-fluid">
                                        </div>
                                        <div v-if="chart2Loader" class="spinner-overlay">
                                            <b-spinner large></b-spinner>
                                        </div>
                                    </div>
                                </div>


                            </template>
                           <!-- <template v-else>
                                <div class="card-body d-flex align-items-center justify-content-center">
                                    <b-spinner large></b-spinner>
                                </div>
                            </template>-->
                        </b-tab>

                    </b-tabs>
                </div>

                <!-- MATCHES CARD -->
                <div class="card mb-0">
                    <b-tabs content-class="p-4" justified class="nav-tabs-custom">

                        <b-tab active>
                           <!-- <template v-slot:title>
                                <i class="uil uil-list-ui-alt font-size-20"></i>
                                <span class="d-none d-sm-block">Matches - {{ today }}</span>
                            </template>-->
                            <template v-if="!showLoader">
                                <div v-if="matchsesData.length">
                                    <div class="row">
                                        <div class="col-3 mb-3 p-0 border-end border-bottom" v-for="item in matchsesData" :key="item.match_id">
                                            <div class="row">
                                                <div class="col-12 text-center">{{item.match_datetime}}</div>
                                                <div class="col-4 p-0 text-center">
                                                    <img :src="item.home_team_logo" alt="" width="30px">
                                                    <p>{{item.home_team_code}}</p>
                                                </div>
                                                <div class="col-4 p-0 d-flex justify-content-center align-items-center">{{item.score_home}} : {{item.score_away}}</div>
                                                <div class="col-4 p-0 text-center">
                                                    <img :src="item.away_team_logo" alt="" width="30px">
                                                    <p>{{item.away_team_code}}</p>
                                                </div>
                                                <div class="col-12 text-center">
                                                    {{item.match_status}}<br>{{item.match_period_name}}
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div v-else>
                                    <p class="text-center">No matches found today</p>
                                </div>
                            </template>
                            <template v-else>
                                <div class="card-body d-flex align-items-center justify-content-center">
                                    <b-spinner large></b-spinner>
                                </div>
                            </template>
                        </b-tab>

                    </b-tabs>
                </div>


            </div>
        </div>
        <div v-else class="text-center mt-5">
            <div class="row">
                <div class="col-md-12">
                    <h4>{{this.error}}</h4>
                </div>
            </div>
        </div>
        <!-- end row -->

        <!-- MODALS -->

        <!-- END  MODALS -->
    </Layout>
</template>
<style>
    .nav-tabs{
        display: none;
    }
</style>